/* Default CSS form when App was created */
.App {
  text-align: center;
}

/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */

/* Added for the coming Soon default */

.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.navbar {
  height: 80px;
  /* Adjust based on your actual navbar's height */
  /* Other styling for your navbar */
}

.coming-soon-container {
  flex-grow: 1;
  /* Add the background image here. 
  /* background-image: url('/path/to/your/image.jpg'); */
  /* Replace with your image path */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  /* Adjust based on your background */
}

h1 {
  margin-bottom: 20px;
  /* Adjust spacing between text and countdown as needed */
}